import { Paper, Button, Typography, Toolbar } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DrillCard, Carousel } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { SubLink } from 'containers'
import CarouselItem from 'components/material/CarouselItem'
import PropTypes from 'prop-types'
import { ACTIVITIES_ROUTE } from 'constants/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const DrillsWidget = ({
  providerUuid,
  language,
  drills,
  providerName,
  handleFavoriteToggle,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Toolbar disableGutters={false} className={classes.toolbar}>
        <Typography variant="h4" component="h4" className={classes.title}>
          {providerName} <Translate id="home.drills" />
        </Typography>
        <Button
          color="primary"
          component={SubLink}
          to={ACTIVITIES_ROUTE}
          endIcon={<ChevronRightIcon />}
        >
          <Translate id="home.read-more" />
        </Button>
      </Toolbar>
      <Carousel>
        {drills.map((drill) => (
          <CarouselItem key={drill.uuid}>
            <DrillCard
              withPhaseMenu={false}
              showAddToTrainingButton={false}
              showDrillActions={false}
              drill={drill}
              onFavoriteToggle={() => handleFavoriteToggle(drill, providerUuid)}
              language={language}
              onPhaseSelect={() => {}}
              onDuplicate={() => {}}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </Paper>
  )
}

DrillsWidget.propTypes = {
  language: PropTypes.string.isRequired,
  providerUuid: PropTypes.string.isRequired,
  handleFavoriteToggle: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  drills: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      images: PropTypes.shape({}),
      duration: PropTypes.number,
      max_players: PropTypes.number,
      min_players: PropTypes.number,
      favorite: PropTypes.bool,
      coaching_points: PropTypes.string,
      drill_flow: PropTypes.string,
      description: PropTypes.string,
      organization: PropTypes.string,
      variations: PropTypes.string,
      video_url: PropTypes.string,
      age_classes: PropTypes.shape(),
    })
  ).isRequired,
}

export default connect(({ auth }) => ({
  language: auth.user.language,
}))(DrillsWidget)
