import React, { useState, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import SearchIcon from '@material-ui/icons/Search'
import { connect } from 'react-redux'
import { Grid, MenuItem, Typography } from '@material-ui/core'
import SearchBar from 'material-ui-search-bar'
import { LoadingSpinner, SelectedMenu } from 'components'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { Translate } from 'react-localize-redux'
import { toast } from 'react-toastify'
import useStyles from '../styles'
import { fetchAllMembers, removeUserRole } from '../api'
import MembersTable from './MembersTable'

const ActiveMembers = ({ teamUuid }) => {
  const classes = useStyles()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [selectedPlayerUuid, setSelectedPlayerUuid] = useState('all')
  const [selectedRoleUuid, setSelectedRoleUuid] = useState('all')
  const [filteredMembers, setFilteredMembers] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMembers = () => {
      setIsLoading(true)

      const filters = {}

      if (searchKeyword) {
        filters['filter[search]'] = searchKeyword
      }
      if (selectedPlayerUuid && selectedPlayerUuid !== 'all') {
        filters['filter[player_uuid]'] = selectedPlayerUuid
      }
      if (selectedRoleUuid && selectedRoleUuid !== 'all') {
        filters['filter[role_uuid]'] = selectedRoleUuid
      }

      fetchAllMembers(teamUuid, filters)
        .then((response) => {
          setFilteredMembers(response.data)

          if (
            !searchKeyword &&
            selectedPlayerUuid === 'all' &&
            selectedRoleUuid === 'all'
          ) {
            setAllMembers(response.data)
          }
        })
        .catch((error) => {
          console.error('Error fetching members:', error)
          toast.error('Error fetching members')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchMembers()
  }, [searchKeyword, selectedPlayerUuid, selectedRoleUuid])

  const groupedMembers = useMemo(() => {
    const groupedData = filteredMembers.reduce((acc, user) => {
      const key = `${user.user_uuid}:${user.user_email}`

      if (!acc[key]) {
        acc[key] = {
          user_uuid: user.user_uuid,
          user_display_name: user.user_display_name,
          user_email: user.user_email,
          roles: [],
          can: user.can,
        }
      }

      acc[key].roles.push({
        role_uuid: user.role_uuid,
        role_display_name: user.role_display_name,
        model_type: user.model_type,
        model_details: user.model_details,
        uhr_uuid: user.uhr_uuid,
      })

      return acc
    }, {})

    return Object.values(groupedData)
  }, [filteredMembers])

  const handleDeleteRole = (uhrUuid) => {
    removeUserRole(uhrUuid)
      .then(() => {
        setFilteredMembers((prevMembers) =>
          prevMembers.filter((member) => member.uhr_uuid !== uhrUuid)
        )
      })
      .catch((error) => {
        console.error('Error removing user role:', error)
        toast.error('Something went wrong')
      })
  }

  const filteredRoles = allMembers.reduce((uniqueRoles, member) => {
    if (!uniqueRoles.some((role) => role.role_uuid === member.role_uuid)) {
      uniqueRoles.push(member)
    }

    return uniqueRoles
  }, [])

  const filteredPlayers = allMembers
    .filter((member) => member.model_type === 'PLAYER')
    .reduce((uniquePlayers, currentPlayer) => {
      if (
        !uniquePlayers.some(
          (players) =>
            players.model_details.player_uuid ===
            currentPlayer.model_details.player_uuid
        )
      ) {
        uniquePlayers.push(currentPlayer)
      }

      return uniquePlayers
    }, [])

  const handleSearchChange = debounce((search) => {
    setSearchKeyword(search)
  }, 400)

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box mt={2}>
          <Box className={classes.filtersContainer}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Translate>
                  {({ translate }) => (
                    <SearchBar
                      className={classes.searchBar}
                      value={searchKeyword}
                      onChange={(value) => handleSearchChange(value)}
                      placeholder={translate('team.members.search')}
                      searchIcon={<SearchIcon fontSize="small" />}
                    />
                  )}
                </Translate>
              </Grid>
              <Grid item>
                <SelectedMenu
                  id="player"
                  margin="dense"
                  value={selectedPlayerUuid}
                  onChange={(e) => setSelectedPlayerUuid(e.target.value)}
                  label={<Translate id="team.members.filter-by-player" />}
                  className={classes.menu}
                >
                  <MenuItem value="all">
                    <Translate id="team.members.any-player" />
                  </MenuItem>
                  {filteredPlayers.map((member) => (
                    <MenuItem
                      key={member.model_details.player_uuid}
                      value={member.model_details.player_uuid}
                    >
                      {member.model_details.player_display_name}
                    </MenuItem>
                  ))}
                </SelectedMenu>
              </Grid>
              <Grid item>
                <SelectedMenu
                  id="role"
                  margin="dense"
                  value={selectedRoleUuid}
                  onChange={(e) => setSelectedRoleUuid(e.target.value)}
                  label={<Translate id="team.members.filter-by-role" />}
                  className={classes.menu}
                >
                  <MenuItem value="all">
                    <Translate id="team.members.any-role" />
                  </MenuItem>
                  {filteredRoles.map((role) => (
                    <MenuItem key={role.role_uuid} value={role.role_uuid}>
                      {role.role_display_name}
                    </MenuItem>
                  ))}
                </SelectedMenu>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {groupedMembers.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <Typography variant="body1">
                  <Translate id="team.members.no-data-available" />
                </Typography>
              </Box>
            ) : (
              <MembersTable
                groupedMembers={groupedMembers}
                onDeleteRole={handleDeleteRole}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

ActiveMembers.propTypes = {
  teamUuid: PropTypes.string.isRequired,
}

export default connect(({ team }) => ({
  teamUuid: team.uuid,
}))(ActiveMembers)
