import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  formateUTCTimeZoneDate,
  formatUtcDateToDateTime,
} from 'utils/formatDate'
import {
  CardHeader,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import eventsIcon from 'assets/images/events@1x.svg'
import { SubLink, ArticlesWidget, DrillsWidget } from 'containers'
import { getClub } from 'utils/localstorage'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import EventPopup from 'components/material/Calender/eventPopup'
import {
  trainings,
  matches,
  generalEvents,
  teams as teamsModule,
} from 'redux/modules'
import { LoadingSpinner } from 'components'
import TaskGroupsWidget from 'containers/TaskManagement/TaskGroupsWidget'
import AllPlayers from 'containers/TeamDashboard/components/AllPlayers'
import useTeamFeature from 'hooks/useTeamFeature'
import {
  fetchDrillProviders,
  fetchClubDrills,
  updateFavoriteDrill,
} from 'containers/ActivitiesContainer/api'
import { EVENT_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import { TRAININGS_GAME } from 'constants/calender'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiButton-root': {
      fontWeight: 600,
    },
  },
  paper: {
    height: '100%',
  },
  sublink: {
    marginRight: theme.spacing(1.9),
  },
  customPrimary: {},
  freeTrialCard: {
    height: 257,
    textAlign: 'center',
  },

  LinearProgress: {
    height: 8,
    borderRadius: 8,
  },
  nextEventList: {
    height: 174,

    padding: '0 ',
    '& .MuiListItem-root': {
      margin: theme.spacing(1.2, 0),
      borderLeft: '3px solid ',
    },
    '& .MuiListItem-root:last-child': {
      marginBottom: '0',
      border: '0',
    },
  },
  errorMessage: {
    marginLeft: 15,
  },
}))

const HomeDashboardContainers = ({
  team,
  createTraining,
  createMatch,
  createEvent,
  scheduleTrainings,
  match,
  articles,
  history,
  fetchTeamNextEvents,
  seasons,
  timezone,
}) => {
  const classes = useStyles()
  const [seasonUuid, setSeasonUuid] = useState(null)
  const [nextTraining, setNextTraining] = useState(null)
  const [nextMatch, setNextMatch] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [drillProviders, setDrillProviders] = useState([])
  const [drillData, setDrillData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)

  const { enabled: hasEditMatchEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.create_match_enabled
  )
  const { enabled: hasEditGeneralEventEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.create_general_event_enabled
  )
  const { enabled: hasTaskManagerEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.task_manager_enabled
  )
  const { enabled: hasAddPlayersEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.add_players_enabled
  )

  const availableTrainingPlayers =
    nextTraining?.relationships?.participants.filter(
      (player) => player.availability === 'AVAILABLE'
    ).length

  const totalTrainingPlayers =
    nextTraining?.relationships?.participants.length || 0

  const availableMatchPlayers = nextMatch?.relationships?.participants.filter(
    (player) => player.availability === 'AVAILABLE'
  ).length

  const totalMatchPlayers = nextMatch?.relationships?.participants.length || 0

  const {
    params: { teamUuid, seasonId },
  } = match

  const teamAgeClassUuid = team.relationships.age_class.uuid

  const [open, setOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const newCreateTraining = (data) => {
    createTraining(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${teamUuid}/season/${seasonId}/trainings/${uuid}`)
    })
  }

  const newCreateMatch = (data) => {
    createMatch(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      if (hasEditMatchEnabled) {
        history.push(`/team/${teamUuid}/season/${seasonId}/matches/${uuid}`)
      } else {
        history.push(`/team/${teamUuid}/season/${seasonId}/events`)
      }
    })
  }
  const newCreateEvent = (data) => {
    createEvent(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      if (hasEditGeneralEventEnabled) {
        history.push(
          `/team/${teamUuid}/season/${seasonId}/general-events/${uuid}`
        )
      } else {
        history.push(`/team/${teamUuid}/season/${seasonId}/events`)
      }
    })
  }
  const newScheduleTrainings = (data) => {
    scheduleTrainings({ ...data, team_id: team.id }).then(() => {
      handleClose()
      history.push(`/team/${teamUuid}/season/${seasonId}/events`)
    })
  }
  const isOwnClubArticles = articles.filter((art) => {
    return art.club_id === getClub()?.id
  })
  const splitArticles =
    getClub().id !== DEFAULT_CLUB_ID && getClub().display_coachbetter_articles

  const handleEventCreate = (gameType) => {
    setTrainingAndGame(gameType)
    setOpen(true)
  }

  useEffect(() => {
    fetchDrillProviders(team.uuid)
      .then((res) => {
        setDrillProviders(res.data)
      })
      .catch((err) => {
        setErrorMessage('Error fetching drill providers')
        console.error('Error fetching drill providers:', err)
      })
  }, [])

  useEffect(() => {
    drillProviders.forEach((provider) => {
      const params = {
        'filter[age_class]': teamAgeClassUuid,
        sort: 'random',
        per_page: 20,
      }

      fetchClubDrills(provider.uuid, params)
        .then((drillRes) => {
          setDrillData((prevDrills) => ({
            ...prevDrills,
            [provider.uuid]: drillRes.data,
          }))
        })
        .catch((err) => {
          setErrorMessage('Error fetching drills')
          console.error(
            `Error fetching drills for provider ${provider.uuid}:`,
            err
          )
        })
    })
  }, [drillProviders])

  const handleFavoriteToggle = (drill, providerUuid) => {
    updateFavoriteDrill(drill.uuid, !drill.favorite)
      .then(() => {
        setDrillData((prevDrills) => {
          const updatedDrills = { ...prevDrills }

          updatedDrills[providerUuid] = updatedDrills[providerUuid]?.map(
            (drillItem) =>
              drill.uuid === drillItem.uuid
                ? { ...drillItem, favorite: !drillItem.favorite }
                : drillItem
          )

          return updatedDrills
        })
      })
      .catch((err) => {
        console.error('Error toggling favorite status:', err)
      })
  }

  // #remove Once season uuid can be accessed from url we dont need to find season like this
  useEffect(() => {
    if (seasons.length > 0) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])

  useEffect(() => {
    setIsLoading(true)
    setError(null)
    if (seasonUuid) {
      fetchTeamNextEvents(seasonUuid)
        .then((res) => {
          setNextMatch(res.response.data.next_match)
          setNextTraining(res.response.data.next_training)
        })
        .catch((err) => {
          console.error('Error fetching next events:', err)
          setError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [seasonUuid])

  return (
    <>
      {open && (
        <EventPopup
          open={open}
          onClose={handleClose}
          trainingAndGame={trainingAndGame}
          createTraining={newCreateTraining}
          createMatch={newCreateMatch}
          scheduleTrainings={newScheduleTrainings}
          createEvent={newCreateEvent}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
        />
      )}

      <div className={classes.root}>
        <Grid container spacing={3} style={{ marginBottom: '10px' }}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <Paper className={classes.paper}>
              <CardHeader
                title={
                  <Typography variant="h4" component="h4">
                    <Translate id="home.next-event" />
                  </Typography>
                }
                action={
                  !isLoading &&
                  !error && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleEventCreate(TRAININGS_GAME.training)}
                    >
                      <Translate id="button.plan-event" />
                    </Button>
                  )
                }
              />
              {isLoading && <LoadingSpinner />}
              {error && (
                <Box className={classes.errorMessage}>
                  Something went wrong!
                </Box>
              )}
              {!isLoading && !error && (
                <List className={classes.nextEventList}>
                  <ListItem>
                    {nextTraining ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="between"
                        alignItems="center"
                        textAlign="left"
                      >
                        <Box mr={3}>
                          <Typography variant="h4" component="h4">
                            {
                              formatUtcDateToDateTime(
                                nextTraining.start_datetime,
                                timezone
                              )[1]
                            }{' '}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            {`${formateUTCTimeZoneDate(
                              nextTraining.start_datetime,
                              timezone,
                              'DD'
                            )} ${formateUTCTimeZoneDate(
                              nextTraining.start_datetime,
                              timezone,
                              'MMM'
                            )}`}
                          </Typography>
                        </Box>
                        <Box>
                          <SubLink to={`/trainings/${nextTraining.uuid}`}>
                            <Typography variant="h4" component="h4">
                              <Translate id="home.trainings" />
                            </Typography>
                            <Typography variant="caption" component="h6">
                              {`${
                                nextTraining.short_address
                                  ? nextTraining.short_address
                                  : ''
                              }`}
                            </Typography>
                          </SubLink>
                        </Box>
                        <Box ml="auto" textAlign="right" color="text.primary">
                          <Typography variant="h4" component="h4">
                            {`${availableTrainingPlayers}/${totalTrainingPlayers}`}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            <Translate id="home.available" />{' '}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <SubLink to={EVENT_ROUTE}>
                        <Box
                          width="100%"
                          mr={1}
                          display="flex"
                          alignItems="center"
                          textAlign="left"
                        >
                          <img src={eventsIcon} alt={eventsIcon} />
                          <Typography variant="body1" component="p">
                            <Translate id="home.no-training-events-planned" />
                          </Typography>
                        </Box>
                      </SubLink>
                    )}
                  </ListItem>
                  <Divider />
                  <ListItem>
                    {nextMatch ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="between"
                        alignItems="center"
                        textAlign="left"
                      >
                        <Box mr={3}>
                          <Typography variant="h4" component="h4">
                            {
                              formatUtcDateToDateTime(
                                nextMatch.start_datetime,
                                timezone
                              )[1]
                            }
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            {`${formateUTCTimeZoneDate(
                              nextMatch.start_datetime,
                              timezone,
                              'DD'
                            )} ${formateUTCTimeZoneDate(
                              nextMatch.start_datetime,
                              timezone,
                              'MMM'
                            )}`}
                          </Typography>
                        </Box>
                        <Box>
                          <SubLink
                            to={
                              hasEditMatchEnabled
                                ? `/matches/${nextMatch.uuid}`
                                : EVENT_ROUTE
                            }
                          >
                            <Typography variant="h4" component="h4">
                              {`${nextMatch.opponent
                                .charAt(0)
                                .toUpperCase()}${nextMatch.opponent.slice(1)}`}
                            </Typography>
                            <Typography variant="h6" component="h6">
                              {`${
                                nextMatch.short_address
                                  ? `${
                                      nextMatch.home === 0 ? 'Away' : 'Home'
                                    } - ${nextMatch.city || nextMatch.country}`
                                  : `${nextMatch.home === 0 ? 'Away' : 'Home'}`
                              }`}
                            </Typography>
                          </SubLink>
                        </Box>
                        <Box ml="auto" textAlign="right" color="text.primary">
                          <Typography variant="h4" component="h4">
                            {`${availableMatchPlayers}/${totalMatchPlayers}`}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            <Translate id="player.available" />{' '}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <SubLink to={EVENT_ROUTE}>
                        <Box
                          width="100%"
                          mr={1}
                          display="flex"
                          alignItems="center"
                          textAlign="left"
                        >
                          <img src={eventsIcon} alt={eventsIcon} />
                          <Typography variant="body1" component="p">
                            <Translate id="home.no-match-events-planned" />
                          </Typography>
                        </Box>
                      </SubLink>
                    )}
                  </ListItem>
                </List>
              )}
            </Paper>
          </Grid>
          {hasAddPlayersEnabled && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <Translate>
                {({ translate }) => (
                  <AllPlayers title={translate('team.whole-team')} />
                )}
              </Translate>
            </Grid>
          )}
          {hasTaskManagerEnabled && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <TaskGroupsWidget />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {errorMessage && (
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper} style={{ padding: '20px' }}>
                <Typography color="error">{errorMessage}</Typography>
              </Paper>
            </Grid>
          )}
          {drillProviders.map((provider) => (
            <Grid
              item
              xs={12}
              md={12}
              lg={drillProviders.length > 1 ? 6 : 12}
              xl={drillProviders.length > 1 ? 6 : 12}
              key={provider.uuid}
            >
              <Paper className={classes.paper}>
                <DrillsWidget
                  providerUuid={provider.uuid}
                  drills={drillData[provider.uuid] || []}
                  providerName={provider.name}
                  handleFavoriteToggle={handleFavoriteToggle}
                />
              </Paper>
            </Grid>
          ))}
          {splitArticles ? (
            <>
              {isOwnClubArticles.length > 0 && (
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <Paper className={classes.paper}>
                    <ArticlesWidget clubid={getClub()?.id} />
                  </Paper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={isOwnClubArticles.length > 0 ? 6 : 12}
                xl={isOwnClubArticles.length > 0 ? 6 : 12}
              >
                <Paper className={classes.paper}>
                  <ArticlesWidget clubid={DEFAULT_CLUB_ID} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper className={classes.paper}>
                <ArticlesWidget clubid={getClub()?.id} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

HomeDashboardContainers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  createTraining: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  scheduleTrainings: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    add_players_enabled: PropTypes.bool,
    relationships: PropTypes.shape({
      age_class: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  seasons: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  fetchTeamNextEvents: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ auth, articles, team, seasons }) => ({
      articles: articles.items,
      team,
      seasons: seasons.items,
      timezone: auth.user.time_zone,
    }),
    {
      createTraining: trainings.createTraining,
      createMatch: matches.createMatch,
      scheduleTrainings: trainings.scheduleOnlyTrainings,
      createEvent: generalEvents.createEvent,
      fetchTeamNextEvents: teamsModule.fetchTeamNextEvents,
    }
  )
)(HomeDashboardContainers)
