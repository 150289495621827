import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Chip,
} from '@mui/material'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import { AlertDialog, DropDownMenu } from 'components'
import { Translate } from 'react-localize-redux'
import { MenuItem } from '@material-ui/core'
import useStyles from '../styles'

const MembersTable = ({ groupedMembers, onDeleteRole }) => {
  const classes = useStyles()

  const [anchorEls, setAnchorEls] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)

  const openOptionsMenu = (event, uuid) => {
    setAnchorEls((prev) => ({ ...prev, [uuid]: event.currentTarget }))
  }

  const closeOptionsMenu = (uuid) => {
    setAnchorEls((prev) => ({ ...prev, [uuid]: null }))
  }

  const handleRevokeClick = (role) => {
    setSelectedRole(role)
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setSelectedRole(null)
  }

  const handleRevokeAccess = () => {
    if (selectedRole) {
      onDeleteRole(selectedRole.uhr_uuid)
      handleDialogClose()
    }
  }

  return (
    <TableContainer>
      {openDialog && (
        <AlertDialog
          open={openDialog}
          onClose={handleDialogClose}
          onCancel={handleDialogClose}
          onSuccess={handleRevokeAccess}
          title={<Translate id="team.members.revoke-access" />}
          subTitle={<Translate id="team.members.revoke-access-confirm" />}
          submitButtonLabel={<Translate id="team.members.revoke" />}
          cancelButtonLabel={<Translate id="button.cancel" />}
        />
      )}
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Translate id="matches.name" />
            </TableCell>
            <TableCell>
              <Translate id="player.email" />
            </TableCell>
            <TableCell>
              <Translate id="team.members.roles" />
            </TableCell>
            <TableCell>
              <Translate id="team.members.role-details" />
            </TableCell>
            <TableCell>
              <Translate id="season.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedMembers.map((member) => (
            <>
              {member.roles.map((role, index) => {
                const canRevoke = member.can.includes('revoke')

                return (
                  <TableRow key={role.uhr_uuid} sx={{ height: '80px' }}>
                    {index === 0 && (
                      <>
                        <TableCell
                          rowSpan={member.roles.length}
                          align="left"
                          sx={{
                            verticalAlign:
                              member.roles.length > 1 ? 'top' : 'middle',
                          }}
                        >
                          {member.user_display_name}
                        </TableCell>
                        <TableCell
                          rowSpan={member.roles.length}
                          align="left"
                          sx={{
                            verticalAlign:
                              member.roles.length > 1 ? 'top' : 'middle',
                          }}
                        >
                          {member.user_email}
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      sx={{
                        borderBottom:
                          index < member.roles.length - 1 ? 'none' : undefined,
                        verticalAlign: 'middle',
                        height: '20px',
                      }}
                    >
                      <Chip
                        label={role.role_display_name}
                        className={classes.chip}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom:
                          index < member.roles.length - 1 ? 'none' : undefined,
                      }}
                    >
                      {role.model_type === 'PLAYER' && role.model_details && (
                        <Typography variant="body2">
                          {role.model_details.player_display_name},{' '}
                          {role.model_details.player_number},{' '}
                          {role.model_details.player_position}
                        </Typography>
                      )}
                      {role.model_type === 'STAFF' && role.model_details && (
                        <Typography variant="body2">
                          {role.model_details.staff_position}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom:
                          index < member.roles.length - 1 ? 'none' : undefined,
                      }}
                    >
                      {canRevoke && (
                        <>
                          <IconButton
                            onClick={(e) => openOptionsMenu(e, role.uhr_uuid)}
                            aria-controls={`simple-menu-${role.uhr_uuid}`}
                            aria-haspopup="true"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <DropDownMenu
                            id={`simple-menu-${role.uhr_uuid}`}
                            anchorEl={anchorEls[role.uhr_uuid]}
                            elevation={2}
                            keepMounted
                            edge="start"
                            open={Boolean(anchorEls[role.uhr_uuid])}
                            onClose={() => closeOptionsMenu(role.uhr_uuid)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleRevokeClick(role)
                                closeOptionsMenu(role.uhr_uuid)
                              }}
                            >
                              <Translate id="team.members.revoke-access" />
                            </MenuItem>
                          </DropDownMenu>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MembersTable.propTypes = {
  groupedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      user_email: PropTypes.string.isRequired,
      user_display_name: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          uhr_uuid: PropTypes.string.isRequired,
          role_display_name: PropTypes.string.isRequired,
          model_type: PropTypes.oneOf(['PLAYER', 'STAFF']).isRequired,
          model_details: PropTypes.oneOfType([
            PropTypes.shape({
              player_display_name: PropTypes.string.isRequired,
              player_number: PropTypes.number.isRequired,
              player_position: PropTypes.string.isRequired,
            }),
            PropTypes.shape({
              staff_position: PropTypes.string.isRequired,
            }),
          ]).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onDeleteRole: PropTypes.func.isRequired,
}

export default MembersTable
