import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
} from '@mui/material'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import { DropDownMenu } from 'components'
import { Translate } from 'react-localize-redux'
import { MenuItem, Typography } from '@material-ui/core'
import useStyles from '../styles'

const PendingInvitesTable = ({
  pendingInvites,
  onResendInvite,
  onRemoveInvite,
}) => {
  const classes = useStyles()

  const [anchorEls, setAnchorEls] = useState({})

  const openOptionsMenu = (event, uuid) => {
    setAnchorEls((prev) => ({ ...prev, [uuid]: event.currentTarget }))
  }

  const closeOptionsMenu = (uuid) => {
    setAnchorEls((prev) => ({ ...prev, [uuid]: null }))
  }

  const handleRevokeClick = (inviteUuid) => {
    onRemoveInvite(inviteUuid)
    closeOptionsMenu(inviteUuid)
  }

  const handleResendInvite = (inviteUuid) => {
    onResendInvite(inviteUuid)
    closeOptionsMenu(inviteUuid)
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Translate id="matches.name" />
            </TableCell>
            <TableCell>
              <Translate id="player.email" />
            </TableCell>
            <TableCell>
              <Translate id="team.members.roles" />
            </TableCell>
            <TableCell>
              <Translate id="team.members.role-details" />
            </TableCell>
            <TableCell>
              <Translate id="season.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingInvites.map((invite) => {
            const canRevoke = invite.can.includes('revoke')
            const canResend = invite.can.includes('resend')

            const showMenu = canRevoke || canResend

            return (
              <TableRow key={invite.uuid} sx={{ height: '80px' }}>
                <TableCell align="left">
                  <Typography className={classes.pendingInvitationText}>
                    <Translate id="team.members.pending-invitation" />
                  </Typography>
                </TableCell>
                <TableCell align="left">{invite.email}</TableCell>
                <TableCell>
                  <Chip
                    label={invite.role_display_name}
                    className={classes.chip}
                    size="small"
                  />
                </TableCell>
                <TableCell>{invite.function}</TableCell>
                <TableCell>
                  {showMenu && (
                    <>
                      <IconButton
                        onClick={(e) => openOptionsMenu(e, invite.uuid)}
                        aria-controls={`simple-menu-${invite.uuid}`}
                        aria-haspopup="true"
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <DropDownMenu
                        id={`simple-menu-${invite.uuid}`}
                        anchorEl={anchorEls[invite.uuid]}
                        elevation={2}
                        keepMounted
                        edge="start"
                        open={Boolean(anchorEls[invite.uuid])}
                        onClose={() => closeOptionsMenu(invite.uuid)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        {canRevoke && (
                          <MenuItem
                            onClick={() => handleRevokeClick(invite.uuid)}
                          >
                            <Translate id="team.members.revoke" />
                          </MenuItem>
                        )}
                        {canResend && (
                          <MenuItem
                            onClick={() => handleResendInvite(invite.uuid)}
                          >
                            <Translate id="team.members.resend" />
                          </MenuItem>
                        )}
                      </DropDownMenu>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PendingInvitesTable.propTypes = {
  pendingInvites: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      function: PropTypes.string.isRequired,
      role_display_name: PropTypes.string.isRequired,
      can: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onResendInvite: PropTypes.func.isRequired,
  onRemoveInvite: PropTypes.func.isRequired,
}

export default PendingInvitesTable
